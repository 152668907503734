import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="super-container d-flex align-items-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12">
            <h1 className="site-title">Oops. There's nothing here!</h1>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
